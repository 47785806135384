import React from 'react';
import { render } from 'react-dom';
import providerHOC from 'app/utils/providerHOC/providerHOC';
import store from 'app/utils/loggedUserInfoHOC/loggedUserInfoStore';
import loggedUserInfoHOC from 'app/utils/loggedUserInfoHOC/loggedUserInfoHOC';
import CSABannerApp from './csaBannerApp';

const CSABannerRoot = providerHOC(store)(loggedUserInfoHOC(CSABannerApp));

render(<CSABannerRoot />, document.getElementById('csa-banner'));
