const { pageType } = window.inlineGlobalConfig;
const { accountName } = window.inlineGlobalConfig.hashedPaths;
const pagesToExcludeBundle = ['my-account'];

if (!pagesToExcludeBundle.includes(pageType)) {
  const mainTag = document.getElementById('main');
  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.src = accountName;
  mainTag.appendChild(scriptTag);
}
