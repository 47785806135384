const { pageType } = window.inlineGlobalConfig;
const { miniCart } = window.inlineGlobalConfig.hashedPaths;
const pagesToExcludeBundle = ['product', 'product-list', 'search-results', 'bag'];

if (!pagesToExcludeBundle.includes(pageType)) {
  const mainTag = document.getElementById('main');
  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.src = miniCart;
  mainTag.appendChild(scriptTag);
}
