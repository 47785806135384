import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const { accountLogoutUrl } = window.inlineGlobalConfig;

const CSABannerApp = (props) => {
  const {
    csAgentUsername, csAgentCartId, csAgentIsLogged, isFetching
  } = props;

  if (isFetching) {
    return null;
  }

  return (
    <div>
      {
        csAgentIsLogged &&
        <div className="csa-banner">
          <p className="csa-banner__content">
            <FormattedMessage id="account.csaBanner.loggedInCustomer" />
            {' '}<strong>{csAgentUsername}</strong>{' '}
            <FormattedMessage id="account.csaBanner.cartId" />
            {' '}<strong>{csAgentCartId}</strong>{' '}
            <a href={accountLogoutUrl}>
              <FormattedMessage id="account.csa.logout" />
            </a>
          </p>
        </div>
      }
    </div>
  );
};

CSABannerApp.propTypes = {
  csAgentUsername: PropTypes.string,
  csAgentCartId: PropTypes.string,
  csAgentIsLogged: PropTypes.bool,
  isFetching: PropTypes.bool
};

const mapStateToProps = (state) => ({
  csAgentUsername: state.LoggedUserInfo.csAgentUsername,
  csAgentCartId: state.LoggedUserInfo.csAgentCartId,
  csAgentIsLogged: state.LoggedUserInfo.csAgentIsLogged,
  isFetching: state.LoggedUserInfo.isFetching
});

export default connect(mapStateToProps)(CSABannerApp);
